<template>
  <div class="home">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mb-md-4 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>
    <!-- </section> -->

    <slider-mobile
      :items="promo"
      class="slider-mobile mt-15 mt-md-4 mb-md-4 mr-md-4 ml-md-4 d-md-none d-lg-none"
    />

    <v-card class="mb-bg mt-md-5 mb-md-5 mr-md-5 ml-md-5">
      <custom-section class="pb-1 pb-md-2">
        <slider
          :items="promo"
          class="mt-md-1 mb-md-2 mt-lg-1 mb-lg-2 slider-promo"
        />
        <!-- <FilterTab class="mt-3 mb-5" /> -->
        <div class="d-flex flex-row">
          <p class="mb-0 jargon-desc ml-0 mr-4">{{ greeting }}</p>
          <h4 class="jargon-title ml-0 mt-0">{{ personName }}</h4>
        </div>
        <!-- FilterTab -->
        <v-card class="card mx-auto mt-3 card-bg" outlined elevation="1">
          <v-list-item three-line>
            <v-list-item-content class="list-item-content">
              <div class="header">
                <h3 class="tagline">
                  {{ $t("field.schedule_an_appointment_new") }}
                </h3>
                <!-- <p class="description mb-1">
                  {{ $t("page.home.search_sub_title") }}
                </p> -->
              </div>
              <v-divider class="white"></v-divider>
              <v-row class="filter-row-home-patient">
                <v-col cols="12" md="4" sm="3" class="service-filter">
                  <v-container fluid>
                    <v-select
                      prepend-inner-icon="mdi-stethoscope"
                      v-model="payload.selectedService"
                      :items="services"
                      item-text="name"
                      item-value="id"
                      :label="$t('field.type_of_test')"
                      multiple
                      color="#00A4B3"
                      class="service inp-text service-fit-content"
                      solo
                      dense
                    >
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index < 1">
                          <template
                            v-if="
                              item[`sub_category_name_${$i18n.locale}`].length >
                              25
                            "
                          >
                            {{
                              item[
                                `sub_category_name_${$i18n.locale}`
                              ].substring(0, 25)
                            }}...
                          </template>
                          <template v-else>
                            {{ item[`sub_category_name_${$i18n.locale}`] }}
                          </template>
                        </span>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >&nbsp; (+{{
                            payload.selectedService.length - 1
                          }}
                          Item)</span
                        >
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggle"
                          class="service-list"
                        >
                          <v-list-item-action>
                            <v-icon
                              :color="
                                payload.selectedService.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{
                                payload.selectedService.length == 0
                                  ? "mdi-checkbox-blank-outline"
                                  : "mdi-close-box"
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title class="content">
                              {{
                                chooseSomeService ||
                                payload.selectedService.length > 0
                                  ? "Unselect all"
                                  : $t("select_all")
                              }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>

                      <template v-slot:item="data">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              payload.selectedService.length == 0
                                ? 'indigo darken-4'
                                : ''
                            "
                          >
                            {{
                              chooseAllService || cekParam(data.item.id)
                                ? "mdi-checkbox-outline"
                                : "mdi-checkbox-blank-outline"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="content">
                            {{ data.item[`sub_category_name_${$i18n.locale}`] }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-container>
                </v-col>
                <v-col cols="12" md="3" sm="3" class="branch-filter">
                  <v-autocomplete
                    v-model="payload.branch"
                    :items="selectbranch"
                    item-value="id"
                    item-text="name"
                    class="filterTab mt-0 mt-md-5 mt-lg-5 inp-text inp-elipse"
                    :label="$t('branch')"
                    color="#00A4B3"
                    prepend-inner-icon="mdi-home-modern"
                    ref="autocomplete"
                    solo
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" sm="3" class="date-filter">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        prepend-inner-icon="mdi-calendar"
                        v-model="dateFormatted"
                        clearable
                        :label="$t('date')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        color="#00a4b3"
                        class="date inp-text"
                        @click:clear="date = null"
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <c-datepicker
                      :plant="payload.branch"
                      checkQueue
                      :minDate="minDate"
                      :value="payload.date"
                      :holiday="getHoliday"
                      :enableDays="getEnableDays"
                      @changeValue="payload.date = $event"
                      @change="menu = false"
                    ></c-datepicker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="2"
                  sm="3"
                  class="mx-auto column-button-search"
                >
                  <localized-link
                    :to="`/patient/test?branch=${this.payload.branch}&date=${
                      this.payload.date
                    }&category=${this.payload.selectedService.toString()}`"
                  >
                    <v-btn
                      class="button float-right d-sm-flex btn-purple"
                      @click="linkSearch($t('branch_cannot_empty'), $event)"
                      rounded
                    >
                      {{ $t("search") }}
                    </v-btn>
                  </localized-link>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <!-- EndFilterTab -->

        <!-- <v-row>
                <v-col>
                  <p class="title-section">What do you feel?</p>
                </v-col>
                <v-col cols="3" align="right">
                  <p class="see-all">See All</p>
                </v-col>
            </v-row> -->
        <!-- <slider-group :items="feel" class="mt-3 mb-2">
        <template v-slot:item>
          <div class="card-feel-margin">
            <div class="boxCard">
              <v-row>
                <v-col
                  cols="5"
                  class="d-flex align-center justify-center"
                  style="padding-left: 15px"
                >
                  <Ilustrator name="dificult" />
                </v-col>
                <v-col cols="7" class="pl-0">
                  <localized-link to="#">
                    <p class="items-title">Dificult <br />Breathing</p>
                  </localized-link>
                </v-col>
              </v-row>
            </div>
          </div>
        </template>
      </slider-group> -->
        <!-- <div class="feel-card">
        <v-slide-group multiple>
          <v-slide-item v-for="(feels, i) in feel" :key="i">
            <div class="card-feel-margin">
              <div class="boxCard">
                <v-row>
                  <v-col
                    cols="5"
                    class="d-flex align-center justify-center"
                    style="padding-left: 15px"
                  >
                    <svg
                      width="30"
                      height="40"
                      viewBox="0 0 30 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.3034 27.6211L8.27648 28.3238L7.18336 35.8585H23.5801L21.7842 22.4288C26.6642 13.0202 24.6732 7.55468 21.8233 4.47054C18.8172 1.26928 14.2105 0.176165 9.99423 1.3864C1.83492 3.72878 1.87396 13.2935 1.87396 13.2935L4.95911e-05 21.1015H1.99108L2.3034 27.6211Z"
                        fill="#EFE7DE"
                      />
                      <path
                        d="M14.9913 21.8042C14.8742 22.3508 14.679 22.8973 14.4057 23.4439C13.781 24.7712 12.8831 26.0205 11.79 26.9574C10.814 27.8163 9.56475 28.441 8.23739 28.4019L8.0422 29.8464C8.00316 29.8464 14.4057 28.6752 14.9913 21.8042Z"
                        fill="#1F3C87"
                      />
                      <path
                        d="M5.81692 12.9812C4.60669 12.9422 4.37245 14.3867 4.29437 15.2846C4.29437 15.4798 4.60669 15.4798 4.60669 15.2846C4.64573 14.5819 4.80189 13.2545 5.81692 13.2936C6.83196 13.3326 6.75388 14.66 6.6758 15.3627C6.63676 15.5579 6.94908 15.5579 6.98812 15.3627C7.0662 14.4648 7.02716 13.0203 5.81692 12.9812Z"
                        fill="#1F3C87"
                      />
                      <path
                        d="M6.48054 24.576C6.2463 25.6301 3.7868 25.7472 2.22521 25.6691L2.06905 22.624C3.74776 22.7021 6.8319 22.9363 6.48054 24.576Z"
                        fill="#FF5457"
                      />
                      <path
                        d="M0.15616 1.19125C1.83487 3.41652 5.30941 2.16725 7.45659 1.50357C12.4146 -0.0580184 18.2706 -0.136098 21.9794 4.0802C25.7272 8.37457 25.493 14.2696 23.9314 19.3838C23.6971 20.1255 21.9794 23.2097 21.3157 23.3268C16.67 24.1466 13.781 18.3687 13.8982 18.3297C15.3817 17.7051 16.8261 16.2216 16.3186 14.4648C16.1625 13.9182 15.4988 12.6689 14.7961 12.747C14.3276 12.7861 14.2105 15.5969 14.1324 15.3627C13.5858 12.8641 10.775 12.5128 8.74491 11.8101C7.18332 11.3025 5.6998 10.5218 4.41149 9.50672C2.06911 7.63281 0.15616 6.61778 0.15616 1.19125Z"
                        fill="#1F3C87"
                      />
                      <path
                        d="M11.0873 32.2279L11.868 33.0477L18.5829 26.6062L17.4898 25.5131L11.0873 32.2279Z"
                        fill="#FF5457"
                      />
                    </svg>
                  </v-col>
                  <v-col cols="7" class="pl-0">
                    <localized-link to="#">
                      <p class="items-title">Dificult <br />Breathing</p>
                    </localized-link>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-slide-item>
        </v-slide-group>
      </div> 
      -->
        <!-- ongoing test -->
        <div class="card-slider" v-if="onGoingTest != null">
          <v-row class="d-flex align-center">
            <v-col>
              <p class="title-section mb-0">
                {{ $t("tab.ongoing_test") }}
              </p>
            </v-col>
            <v-col cols="3" align="right">
              <localized-link :to="'/patient/profile?tab=order'">
                <p class="see-all mb-0">{{ $t("see_all") }}</p>
              </localized-link>
            </v-col>
          </v-row>
          <div class="box-row">
            <v-row>
              <v-col>
                <card-order-list :data="onGoingTest" lastOrder>
                </card-order-list>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- row satu Simple Profile -->
        <v-row class="ml-auto mt-2 first-row">
          <v-col cols="6" md="2" lg="2" class="column-icon">
            <icons icon="simple-profile" class="icon" />
          </v-col>
          <v-col cols="6" md="4" lg="4">
            <v-row class="mt-2"
              ><p class="jargon-title">
                {{ $t("page.home.list_benefit_1.title") }}
              </p></v-row
            >
            <v-row class="mt-1"
              ><p class="jargon-desc">
                {{ $t("page.home.list_benefit_1.sub_title") }}
              </p></v-row
            >
          </v-col>

          <v-col cols="6" md="2" lg="2" class="second-column">
            <icons class="icon" icon="simple-reschedule" />
          </v-col>
          <v-col cols="6" md="4" lg="4" class="">
            <v-row class="mt-2"
              ><p class="jargon-title">
                {{ $t("page.home.list_benefit_2.title") }}
              </p></v-row
            >
            <v-row class="mt-1"
              ><p class="jargon-desc">
                {{ $t("page.home.list_benefit_2.sub_title") }}
              </p></v-row
            >
          </v-col>
        </v-row>
        <!-- end row satu -->

        <!-- row dua simple booking-->
        <v-row class="ml-auto second-row">
          <v-col cols="6" md="2" lg="2" class="column-icon">
            <icons class="icon" icon="simple-booking" />
          </v-col>
          <v-col cols="6" md="4" lg="4">
            <v-row class="mt-2"
              ><p class="jargon-title">
                {{ $t("page.home.list_benefit_3.title") }}
              </p></v-row
            >
            <v-row class="mt-1"
              ><p class="jargon-desc">
                {{ $t("page.home.list_benefit_3.sub_title") }}
              </p></v-row
            >
          </v-col>
          <!-- ---------- -->
          <v-col cols="6" md="2" lg="2" class="second-column">
            <icons class="icon" icon="simple-result" />
          </v-col>
          <v-col cols="6" md="4" lg="4">
            <v-row class="mt-2"
              ><p class="jargon-title">
                {{ $t("page.home.list_benefit_4.title") }}
              </p></v-row
            >
            <v-row class="mt-1"
              ><p class="jargon-desc">
                {{ $t("page.home.list_benefit_4.sub_title") }}
              </p></v-row
            >
          </v-col>
        </v-row>
        <!-- end row dua -->

        <!-- end row card test -->
        <!-- aulio sementara nambahi end-->
        <DialogMenu
          :tampilkan="dialog"
          @toggleDialog="dialog => toggleDialogMenu(dialog)"
        />
      </custom-section>

      <div class="margin-last-med">
        <LastMedicalRecord />
      </div>
      <!-- row card test -->
      <div class="card-slider px-1 px-md-3" v-if="popularTest.length > 0">
        <v-row class="d-flex align-center" dense>
          <v-col>
            <p class="title-section mb-0">
              {{ $t("page.home.popular_test") }}
            </p>
          </v-col>
          <v-col cols="5" align="right">
            <localized-link :to="'/patient/test'">
              <p class="see-all mb-0">{{ $t("see_all") }}</p>
            </localized-link>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="box-row">
          <v-row class="mb-2 mx-0">
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="(test, index) of popularTest"
              :key="index"
            >
              <card-test-item
                :active="checkItem(test)"
                :title="
                  $i18n.locale == 'id'
                    ? test.name
                    : test[`name_${$i18n.locale}`]
                "
                :action="
                  payload.branch != '' &&
                  payload.branch != null &&
                  payload.branch != undefined
                "
                :plant="
                  payload.branch != '' &&
                  payload.branch != null &&
                  payload.branch != undefined
                    ? payload.branch
                    : ''
                "
                :sub_title="`IDR ${
                  test.prices != undefined
                    ? test.prices.length !== 0
                      ? formater(test.prices[0].amount)
                      : '0'
                    : '0'
                }.00`"
                :desc="test[`description_${$i18n.locale}`]"
                :data="test"
                @addCart="item => addCart(item)"
                @removeCart="item => removeCart(item)"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <v-dialog v-model="dialogConfirmation" persistent max-width="290">
        <v-card class="pa-3">
          <div class="d-flex justify-center mb-3">
            <v-icon x-large color="yellow"> mdi-information-outline </v-icon>
          </div>
          <p class="text-center">
            {{ $t("some_product_not_available_in_this_branch") }}
          </p>
          <div class="d-flex justify-center">
            <c-button class="mx-2 py-2" rounded @click="continueAddToCart()">{{
              $t("continue")
            }}</c-button>
            <c-button
              class="mx-2 py-2"
              rounded
              outline
              @click="
                () => {
                  dialogConfirmation = false;
                  editedItem = {};
                }
              "
              >{{ $t("cancel") }}</c-button
            >
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
// import TextField from "@/components/v2/input/text_field.vue";
// import Cart from "@/components/headers/Cart.vue";
// import HeadingName from "@/components/Heading/HeadingName.vue";
import slider from "@/components/v2/slider/index.vue";
import SliderMobile from "@/components/v2/slider/index_mobile.vue";

// import sliderGroup from "@/components/v2/slider/slider_group.vue";
// import Ilustrator from "@/components/elements/ilustrator/index.vue";
// import Tab from "@/components/v2/switch_account/tab.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
//import FilterTab from "@/views/v2/Patient/filter/index.vue";
import CustomSection from "@/components/v2/section/index.vue";
import icons from "@/components/elements/Icons.vue";
import moment from "moment-timezone";
import { format, parseISO } from "date-fns";
//import ServiceFilter from "@/views/v2/Patient/filter/service.vue";
// import UserCard from "@/components/v2/sidebar/UserCard.vue";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
// import notification from "@/components/v2/notification/index.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import LastMedicalRecord from "@/views/v2/Patient/profile/last_medical_record.vue";
import CardTestItem from "@/components/v3/cards/card-test.vue";
import CButton from "@/components/v2/button/index.vue";
import CardOrderList from "@/components/v3/cards/card-order-list.vue";
import CDatepicker from "@/components/v3/date/custom-datepicker.vue";

export default {
  name: "Home",
  components: {
    CustomSection,
    slider,
    icons,
    DialogMenu,
    SliderMobile,
    NavbarOne,
    LastMedicalRecord,
    CardTestItem,
    CButton,
    CardOrderList,
    CDatepicker,
  },
  data: () => ({
    onGoingTest: null,
    dialog: false,
    lastScrollPosition: 0,
    promo: [],
    feel: [
      {
        title: "Dificult Breathing",
        src: "../assets/banner.jpeg",
      },
      {
        title: "Sore Throat",
        src: "../assets/banner.jpeg",
      },
      {
        title: "Fever",
        src: "../assets/banner.jpeg",
      },
      {
        title: "Dificult Breathing",
        src: "../assets/banner.jpeg",
      },
      {
        title: "Sore Throat",
        src: "../assets/banner.jpeg",
      },
      {
        title: "Fever",
        src: "../assets/banner.jpeg",
      },
      {
        title: "Dificult Breathing",
        src: "../assets/banner.jpeg",
      },
      {
        title: "Sore Throat",
        src: "../assets/banner.jpeg",
      },
      {
        title: "Fever",
        src: "../assets/banner.jpeg",
      },
    ],
    popularTest: {},
    modal_datePicker: false,
    datePicker: null,
    branch: [
      { name: "Malang", abbr: "MLG", id: 1 },
      { name: "Yogyakartya", abbr: "JOG", id: 2 },
      { name: "Solo", abbr: "SOC", id: 3 },
      { name: "Tangerang", abbr: "CGK", id: 4 },
      { name: "Banjarmasin", abbr: "BDJ", id: 5 },
    ],
    menu: false,
    patient: [
      { name: "10 Patients", id: 1 },
      { name: "11 Patients", id: 2 },
      { name: "8 Patients", id: 3 },
      { name: "9 Patients", id: 4 },
      { name: "11 Patients", id: 5 },
    ],
    symptom: [
      { name: "Headache", id: 1 },
      { name: "Fever", id: 2 },
      { name: "Sore Throat", id: 3 },
      { name: "Cough", id: 4 },
      { name: "Eye Sore", id: 5 },
      { name: "Breathing Difficulty", id: 6 },
    ],
    selectbranch: [],
    payload: {
      branch: "",
      date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      dateSearch: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      serviceName: "",
      category: "",
      selectedService: [],
    },
    paramBranchSLug: "default",
    servicesX: [
      "Medical Check Up",
      "Biomolecules Test",
      "Hematalogy Test",
      "Imunology Test",
      "Blood Chemistry Test",
      "Mikrobiology Test",
      "Anatomic Patology Test",
      "Radiology Test",
      "Toxicology Test",
      "Immunization ",
      "Vaccination",
      "Wound Care",
    ],
    services: [],
    listTest: [],
    getTestItemHeader: [],
    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    dialogConfirmation: false,
    // start available date
    availableDates: [],
    // end available date
  }),
  watch: {
    "payload.branch": async function () {
      history.replaceState(null, null, `?branch=${this.payload.branch}`);
      this.getTopProduct();

      const minDate = this.minDate; // Replace with your minimum date or leave as null
      const maxDate = null; // Replace with your maximum date or leave as null

      this.payload.date = this.findClosestValidDate(minDate, maxDate);
    },
  },
  async mounted() {
    this.promo = [
      {
        src_mobile: `/virtu-page-4-${this.$i18n.locale}.jpg`,
        src_dekstop: `/virtu-page-desktop-${this.$i18n.locale}.jpg`,
      },
      {
        src_mobile: `/virtu-page-2-${this.$i18n.locale}.jpg`,
        src_dekstop: `/virtu-page-desktop-${this.$i18n.locale}.jpg`,
      },
      {
        src_mobile: `/virtu-page-3-${this.$i18n.locale}.jpg`,
        src_dekstop: `/virtu-page-desktop-${this.$i18n.locale}.jpg`,
      },
    ];
    this.$store.commit("setLoading", true);
    await API.get(
      `${process.env.VUE_APP_API_TRUST}plant/get?show_opening_hours=true`,
      {
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then(resp => {
      this.selectbranch = resp.results;
    });

    if (this.$store.state.cart.length > 0) {
      if (this.payload.branch == "" || this.payload.branch == null) {
        this.payload.branch = parseInt(this.$store.state.cart[0].plant.id);
        this.getParBranch = this.$store.state.cart[0].plant.id;
        this.paramBranchSLug = this.$store.state.cart[0].plant.id;
      }
    }

    if (typeof this.$route.query.branch == "string") {
      this.payload.branch = parseInt(this.$route.query.branch);
    }

    // category
    API.get(`${process.env.VUE_APP_API_TRUST}product-subcategory/get`, {
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(res => {
      if (res.statusCode == 200) {
        this.services = res.results;
      }
    });
    // API.get(
    //   `${process.env.VUE_APP_API_TRUST}product/get-category`,
    //   {}
    // ).then(res => {
    //   if (res.statusCode == 200) {
    //     this.services = res.results;
    //   }
    // });
    this.getTopProduct();
    this.getOngoingProduct();
    this.payload.date = this.minDate;

    this.$store.commit("setLoading", false);

    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window, removeEventListener("scroll", this.onScroll);
  },

  computed: {
    // start holiday
    getHoliday() {
      const indexPlant = this.selectbranch.findIndex(
        res => res.id == this.payload.branch
      );
      if (indexPlant > -1) {
        return this.selectbranch[indexPlant].upcoming_holidays;
      }
      return [];
    },
    // end holiday

    // start enable days
    getEnableDays() {
      const indexPlant = this.selectbranch.findIndex(
        res => res.id == this.payload.branch
      );
      let enableDays = [];
      if (indexPlant > -1) {
        const days = [
          "Minggu",
          "Senin",
          "Selasa",
          "Rabu",
          "Kamis",
          "Jumat",
          "Sabtu",
        ];
        const { opening_hours } = this.selectbranch[indexPlant];
        for (let i = 0; i < days.length; i++) {
          const indexDays = opening_hours.findIndex(
            item => item.day == days[i]
          );
          if (indexDays > -1) {
            enableDays.push(i);
          }
        }
      }
      return enableDays;
    },
    // end enable days
    minDate() {
      // Set the start and end time boundaries
      let currentTime = moment();
      const indexPlant = this.selectbranch.findIndex(
        res => res.id == this.payload.branch
      );
      // check timezone time
      if (this.payload.branch != null && this.payload.branch != "") {
        if (indexPlant > -1) {
          const timezone = moment.tz.guess();
          if (this.selectbranch[indexPlant].timezone_area == timezone) {
            currentTime = moment();
          } else {
            const now = moment().format("HH:mm:ss");
            currentTime = moment(
              this.convertTime(
                now,
                moment.tz.guess(),
                this.selectbranch[indexPlant].timezone_area
              ),
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        }
      }

      let startTime = "";
      let endTime = "";
      if (indexPlant > -1) {
        const { opening_hours } = this.selectbranch[indexPlant];
        const indexByDayName = opening_hours.findIndex(
          item => item.day == currentTime.locale("ID").format("dddd")
        );
        if (indexByDayName > -1) {
          const close = opening_hours[indexByDayName].hours
            .split("-")[1]
            .replace(":00", "");
          startTime = moment()
            .hour(0) // mulai jam 00:00
            .minute(0)
            .second(0);
          endTime = moment().hour(close).minute(0).second(0);
        }
      }
      // Check if the current time is between the start and end time
      const isWithinTimeRange =
        indexPlant > -1 ? currentTime.isBetween(startTime, endTime) : true;

      // Output the result
      let add = 0;
      while (
        add < 10 &&
        this.getHoliday.some(
          data =>
            data.date ==
            currentTime.clone().add(add, "days").format("YYYY-MM-DD")
        )
      ) {
        add++;
      }
      if (!isWithinTimeRange) {
        return moment()
          .add(add + 1, "days")
          .format("YYYY-MM-DD");
      } else {
        return moment().add(add, "days").format("YYYY-MM-DD");
      }
    },
    greeting() {
      const now = moment();
      const currentHour = now.hour();
      if (currentHour >= 5 && currentHour < 12) {
        return `${this.$t("good_morning")} ✨ `;
      } else if (currentHour >= 12 && currentHour < 18) {
        return `${this.$t("good_afternoon")} ✨ `;
      } else {
        return `${this.$t("good_evening")} ✨`;
      }
    },
    personName() {
      return this.$store.state.profileData.person.name;
    },
    dateFormatted() {
      return this.formatDate(this.payload.date);
    },
    chooseAllService() {
      return this.payload.selectedService.length === this.services.length;
    },
    chooseSomeService() {
      return this.payload.selectedService.length > 0 && !this.chooseAllService;
    },
    icon() {
      if (this.chooseAllService) return "mdi-close-box";
      if (this.chooseSomeService) return "mdi-plus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    // start near date
    findClosestValidDate(minDate, maxDate) {
      const startDate = moment(this.payload.date);
      const getEnableDays = this.getEnableDays;

      // Exclude the holiday on August 17th, 2023 (which is a Thursday)
      // getEnableDays.push(moment("2023-08-17").day());

      // return same day when enable
      if (
        getEnableDays.includes(startDate.day()) &&
        (!minDate || startDate.isSameOrAfter(minDate)) &&
        (!maxDate || startDate.isSameOrBefore(maxDate))
      ) {
        return startDate.format("YYYY-MM-DD");
      }
      let closestDateBefore = null;
      let closestDateAfter = null;
      let daysDifferenceBefore = Infinity;
      let daysDifferenceAfter = Infinity;

      for (let daysToAdd = 1; daysToAdd <= 7; daysToAdd++) {
        const candidateDateBefore = startDate
          .clone()
          .subtract(daysToAdd, "days");
        const candidateDateAfter = startDate.clone().add(daysToAdd, "days");

        const isAfterMinDateBefore =
          !minDate || candidateDateBefore.isSameOrAfter(minDate);
        const isBeforeMaxDateBefore =
          !maxDate || candidateDateBefore.isSameOrBefore(maxDate);

        if (
          getEnableDays.includes(candidateDateBefore.day()) &&
          isAfterMinDateBefore &&
          isBeforeMaxDateBefore
        ) {
          const currentDifference = Math.abs(
            candidateDateBefore.diff(startDate, "days")
          );
          if (currentDifference < daysDifferenceBefore) {
            closestDateBefore = candidateDateBefore;
            daysDifferenceBefore = currentDifference;
          }
        }

        const isAfterMinDateAfter =
          !minDate || candidateDateAfter.isSameOrAfter(minDate);
        const isBeforeMaxDateAfter =
          !maxDate || candidateDateAfter.isSameOrBefore(maxDate);

        if (
          getEnableDays.includes(candidateDateAfter.day()) &&
          isAfterMinDateAfter &&
          isBeforeMaxDateAfter
        ) {
          const currentDifference = Math.abs(
            candidateDateAfter.diff(startDate, "days")
          );
          if (currentDifference < daysDifferenceAfter) {
            closestDateAfter = candidateDateAfter;
            daysDifferenceAfter = currentDifference;
          }
        }
      }
      // Compare the closest dates before and after and choose the closer one
      if (closestDateBefore && closestDateAfter) {
        return closestDateBefore.isBefore(closestDateAfter)
          ? closestDateBefore.format("YYYY-MM-DD")
          : closestDateAfter.format("YYYY-MM-DD");
      } else if (closestDateBefore) {
        return closestDateBefore.format("YYYY-MM-DD");
      } else if (closestDateAfter) {
        return closestDateAfter.format("YYYY-MM-DD");
      } else {
        return null;
      }
    },
    // end near date

    // start convert time
    convertTime(timeString, sourceTimeZone, targetTimeZone) {
      // Parse the original time string in the source timezone
      const originalTime = moment.tz(timeString, "HH:mm:ss", sourceTimeZone);

      // Convert the time to the target timezone
      const convertedTime = originalTime.clone().tz(targetTimeZone);

      // Format the converted time as "HH:mm:ss"
      const formattedTime = convertedTime.format("YYYY-MM-DD HH:mm:ss");

      return formattedTime;
    },
    // end convert time

    cekParam(value) {
      return this.payload.selectedService.indexOf(value) > -1 ? true : false;
    },
    async getOngoingProduct() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}registration/get-order-list?approved=false&per_page=1&status=paid&upcoming=true`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          if (resp.results.length > 0) {
            this.onGoingTest = resp.results[0];
          } else {
            this.onGoingTest = null;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getTopProduct() {
      if (
        this.payload.branch == null ||
        this.payload.branch == "" ||
        this.payload.branch == undefined
      ) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}statistic/get-topproduct?per_page=6`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          if (res.statusCode === 200) {
            this.popularTest = res.results;
          }
        });
      } else {
        API.get(
          `${process.env.VUE_APP_API_TRUST}product/get?per_page=6&plant_id=${
            this.payload.branch == null ? "" : this.payload.branch
          }`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        )
          .then(response => {
            if (response.statusCode === 200) {
              this.paramBranchSLug = this.payload.branch;
              this.popularTest = response.results;
            } else if (response.statusCode == 400) {
              Object.keys(response.errors).forEach(element => {
                this.toast(
                  "error",
                  typeof response.errors[element] == "object"
                    ? response.errors[element][0]
                    : response.errors[element]
                );
              });
            } else {
              this.$swal({
                icon: "error",
                text: response.message,
                //text: this.$t("error_try_again_latter"),
              });
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              text: "Error search data!",
            });
          });
      }
    },
    formater(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(par) {
      if (!par) return null;
      return moment(par)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    linkSearch(message, event) {
      if (this.payload.branch == "") {
        event.preventDefault();
        this.$swal({
          icon: "error",
          text: message,
        }).then(() => {
          this.$refs.autocomplete.focus();
        });
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.chooseAllService) {
          this.payload.selectedService = [];
        } else {
          if (this.chooseSomeService) {
            this.payload.selectedService = [];
          } else {
            let newVar = [];
            this.services.forEach(element => {
              newVar = [...newVar, element.id];
            });
            this.payload.selectedService = newVar;
          }
        }
      });
    },

    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },
    // start cart func
    async updateCartApi() {
      await this.$store.dispatch("fetchCartData");
    },
    continueAddToCart() {
      const addCartTemp = {
        from_package: false,
        qty: 1,
        plant: {
          id: this.payload.branch,
        },
        product: {
          id: this.editedItem.id,
          name: this.editedItem.name,
          name_en: this.editedItem.name_en,
          prices: [
            {
              payment_method_id: 1,
              amount: this.editedItem.prices[0].amount,
            },
          ],
        },
      };
      // update cart before store db
      this.$store.commit("updateCart", [
        ...this.$store.state.cart,
        addCartTemp,
      ]);

      this.dialogConfirmation = false;
      const dataReady = [
        {
          plant_id: this.branchPar,
          package_id: null,
          from_package: false,
          product_id: this.editedItem.id,
          qty: 1,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
          this.editedItem = {};
        }
      });
    },

    checkItem(item) {
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.payload.branch;
      } else {
        if (this.$route.query.branch === this.payload.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.payload.branch;
        }
      }
      if (
        this.$store.state.cart.length > 0 &&
        this.$store.state.cart[0].plant != undefined
      ) {
        if (this.branchPar == this.$store.state.cart[0].plant.id) {
          return this.$store.state.cart.findIndex(
            //res => res.product.id == item.id
            res => res.product != null && res.product.id == item.id
          ) < 0
            ? false
            : true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    addCart(data) {
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.payload.branch;
      } else {
        //jika ada param url branch tapi memilih branch lain di menu pilihan branch
        if (this.$route.query.branch === this.payload.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.payload.branch;
        }
      }

      const addCartTemp = {
        from_package: false,
        qty: 1,
        plant: {
          id: this.payload.branch,
        },
        product: {
          id: data.id,
          name: data.name,
          name_en: data.name_en,
          prices: [
            {
              payment_method_id: 1,
              amount: data.prices[0].amount,
            },
          ],
        },
      };
      if (this.$store.state.cart.length > 0) {
        if (this.branchPar == this.$store.state.cart[0].plant.id) {
          // update cart before store db
          this.$store.commit("updateCart", [
            ...this.$store.state.cart,
            addCartTemp,
          ]);
          const dataReady = [
            {
              plant_id: this.branchPar,
              package_id: null,
              from_package: false,
              product_id: data.id,
              qty: 1,
            },
          ];
          API.post(
            `${process.env.VUE_APP_API_TRUST}cart/store`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            dataReady
          ).then(res => {
            if (res.statusCode == 200) {
              this.updateCartApi();
            }
          });
        } else {
          this.dialogConfirmation = true;
          this.editedItem = data;
        }
      } else {
        // update cart before store db
        this.$store.commit("updateCart", [
          ...this.$store.state.cart,
          addCartTemp,
        ]);
        const dataReady = [
          {
            plant_id: this.branchPar,
            package_id: null,
            from_package: false,
            product_id: data.id,
            qty: 1,
          },
        ];
        API.post(
          `${process.env.VUE_APP_API_TRUST}cart/store`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        ).then(res => {
          if (res.statusCode == 200) {
            this.updateCartApi();
          }
        });
      }
    },
    removeCart(data) {
      // remove before store
      const cart = this.$store.state.cart;
      const indexCart = cart.findIndex(res => res.product.id == data.id);
      cart.splice(indexCart, 1);
      this.$store.commit("updateCart", cart);
      // const is_package =
      //   this.dataMyPackage.findIndex(
      //     (dataGetPackage) => dataGetPackage.id == data.id
      //   ) >= 0;
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.payload.branch;
      } else {
        if (this.$route.query.branch === this.payload.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.payload.branch;
        }
      }
      const dataReady = [
        {
          plant_id: this.branchPar,
          package_id: null,
          from_package: false,
          product_id: data.id,
          qty: 0,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    // end cart func
  },
};
</script>

<style lang="scss">
// .v-sheet.v-card:not(.v-sheet--outlined){
//   box-shadow: unset !important;
// }
.margin-last-med {
  margin: 12px;
}
.mb-bg {
  margin: 12px;
}
.m-divider {
  display: none;
}
@media screen and (max-width: 913px) {
  .margin-last-med {
    margin: 4px;
  }
}
@media screen and (max-width: 767px) {
  .home {
    background: white !important;
    padding: 0 !important;
  }
  .mb-bg {
    margin: unset !important;
  }
  // .card-bg {
  //   background-color: #00a4b3 !important;
  // }
  // .btn-purple {
  //   background-color: #1f3c87 !important;
  //   color: #fff;
  // }
  .tagline {
    color: #fff !important;
  }
  // .theme--light.v-divider {
  //    // border-color: #fff !important;
  //     border: 2px solid #00a4b3;
  //     margin: 10px 10px 0 10px;
  //     margin-left: 10px;
  // }
  .inp-text {
    background-color: #fff;
    border-radius: 10px;
    height: 40px;
    padding: 4px;
    font-size: 12px !important;
  }
  .service-fit-content {
    height: fit-content !important;
  }
  .service-fit-content > .v-input__control > .v-input__slot {
    margin-bottom: 0 !important;
  }
  .service-fit-content > .v-input__control > .v-text-field__details {
    display: none !important;
  }
  #input-88 {
    display: none !important;
  }
  .v-text-field > .v-input__control > .v-input__slot:before {
    border-style: unset !important;
  }
  .date {
    margin-top: 15px !important;
  }
  .v-application--is-ltr .v-text-field .v-label {
    transform-origin: unset !important;
  }
  .v-text-field .v-label--active {
    display: none;
  }
  .display-desktop {
    display: none !important;
  }
  .popular-action-home {
    .popular-price {
      margin-top: -10px !important;
    }
  }
}
.inp-elipse
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > input[type="text"] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: -webkit-box;
}
</style>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";

.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}
.tab-switch {
  color: $main_2;
  font-size: 14px;
  margin-right: 8px;
}

//aulio sementara nambah
.menu {
  margin-top: 12px;
}
p.title-section {
  color: #1f3c87;
  font-size: 16px;
  font-weight: 700;
  margin-left: 8px;
}
.see-all {
  font-size: 16px;
  color: #00a4b3;
  margin-right: 8px;
  margin-bottom: 0px;
}
.card-slider {
  margin-top: 16px;
  //padding: 0px 8px;
}
.feel-card {
  padding: 12px 0px;
}
.card-feel-margin {
  margin: 5px 10px 5px 2px;
}
.boxCard {
  position: relative;
  background: #ffffff;
  border-radius: 10px !important;
  padding: 10px;
  overflow: hidden;
  height: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.items-title {
  font-size: 14px;
  color: #00a4b3;
  font-weight: 600;
  margin-bottom: 0px;
}
a {
  text-decoration: none;
}
.box-row {
  padding: 12px 0px 12px 0px;
}
.btn-detail {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: #00a4b3 !important;
  color: #fff;
  font-size: 12px;
  text-transform: capitalize;
  height: 20px;
}

.jargon-title {
  font-size: 18px;
  font-weight: bold;
  color: #1f3c87;
  margin-left: -15px;
}

.jargon-desc {
  font-size: 16px;
  color: #00a4b3;
  margin-bottom: 9px;
  margin-left: -15px;
}

.v-application p {
  margin-bottom: 9px;
}

.text-field {
  max-width: 70%;
  margin-top: -3px;
  // margin-right: 52px;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 10% !important;
}

@media screen and (max-width: 600px) {
  .column-button-search {
    margin-top: -10px !important;
    margin-bottom: 10px;
  }
  .filter-row-home-patient {
    margin-top: -10px !important;
  }

  .branch-filter {
    margin-top: -15px;
  }

  .date-filter {
    margin-top: -28px !important;
  }
  .jargon-title {
    font-size: 13px;
    margin-left: -55px;
    margin-top: 5px;
  }
  .jargon-desc {
    font-size: 12px;
    margin-left: -55px;
  }
  .column-icon {
    margin-left: -13px !important;
  }
  .simple-profile {
    width: 73px !important;
    height: 60px !important;
  }
  .btn-detail {
    width: 100%;
  }

  .text-field {
    max-width: 85%;
    margin-left: 0px !important;
  }

  .popular-action {
    margin-top: -25px;
    text-align: center;
  }

  .card-top {
    position: fixed;
    z-index: 999;
    transition: ease-in-out 0.3s;
    top: 0px;
  }

  .card-top.card-top--hidden {
    transform: translate3d(0, -150%, 0);
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }

  .popular-price {
    margin-top: -4px;
    float: right;
  }
  .mx-auto {
    margin-top: 10px;
  }
  .cart {
    margin-right: 11px;
    z-index: 1;
  }

  .bell-mobile {
    margin-left: 8px;
  }

  .user-card {
    margin-top: -4px;
    max-width: 85% !important;
    margin-right: -11px;
  }

  .user-card .user-card__info {
    display: flex;
  }

  .slider-promo {
    display: none;
  }

  .card-top {
    position: fixed;
    width: 100%;
    display: block;
    // transition: top 0.3s;
    margin-top: -3px;
  }

  .col-simple-profile {
    margin-left: -115px;
  }

  .second-column {
    margin-left: -10px;
  }

  .notification {
    height: 55px;
    border-radius: 3px;
    margin-top: -15px;
    margin-left: -33px;
  }

  .first-row {
    margin-left: -10px;
  }

  .second-row {
    margin-left: -10px;
  }
  .popular-title {
    margin-bottom: -4px !important;
  }
}

@media screen and (max-width: 765px) {
  .filterTab {
    padding-top: 4px !important;
  }
}
@media screen and (min-width: 765px) and (max-width: 1023px) {
  // .filterTab {
  //   padding-top: 16px !important;
  // }
  .jargon-desc {
    padding: 2px !important;
  }
}
//aulio sementara nambah end
// stylefilterTab
.header {
  margin-bottom: 11px;
  margin-top: 2px;
}

.tagline {
  font-size: 20px;
  font-weight: bold;
  // color: #1f3c87;
  color: #fff;
}

.description {
  font-size: 15px;
  color: black;
  font-weight: 200;
  margin-top: 10px;
}

.branch {
  margin-left: -17px;
}

.service-filter {
  margin-top: 5px;
  padding: 0;
}

.date-filter {
  margin-top: 5px;
}

.mx-auto {
  margin-top: 5px;
}

.filter-row-home-patient {
  margin-top: 5px;
  font-size: 12px;
}

.filterTab {
  text-transform: capitalize;
  color: #1f3c87;
  font-weight: bold;
  padding-top: 0px;
  margin-top: 5px !important;
  font-size: 12px;
}
.button.v-btn.v-btn--has-bg {
  background: #00a4b3;
}
.button {
  font-size: 12px;
  text-align: center;
  width: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: #00a4b3;
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
  height: 25px;
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
}
.v-label {
  font-size: 12px;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 100px;
  padding: 0 16px;
}

.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
  margin-bottom: 10px;
  margin-top: -30px;
}

.v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  font-weight: 200;
}

.v-autocomplete.v-select.v-input input {
  min-width: 64px;
  font-weight: 200;
  font-size: 12px;
}

// .cart {
//    margin-left: 380px;
// }

.filter-row-home-patient .theme--light.v-icon {
  color: #1f3c87;
}

.filter-row-home-patient .v-label {
  font-size: 12px;
  line-height: 1;
  min-height: 2px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.filter-row-home-patient .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  text-transform: capitalize;
}

.filter-row-home-patient .v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 200;
}
.filter-row-home-patient .v-text-field input {
  font-size: 12px;
}
.filter-row-home-patient
  .v-autocomplete.v-input
  > .v-input__control
  > .v-input__slot {
  font-size: 12px;
  font-weight: 200;
}
.date {
  text-transform: capitalize;
  font-size: 12px;
}
.margin-btn-detail {
  margin-top: 25px;
}
// endstylefilterTab
// .row--dense {
//   margin: 10px;
// }

//service
.v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  text-transform: capitalize;
}

.content {
  color: #1f3c87;
  font-weight: bold;
  font-size: 12px;
}
.service {
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
  font-size: 12px;
}
//EndService
.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 95%;
  margin-top: -19px;
  float: right;
}
.padding-box {
  margin-bottom: 8px;
  @media screen and (min-width: 960px) {
    margin-bottom: 12px;
    &:nth-child(odd) {
      padding-right: 10px;
    }
    &:nth-child(even) {
      padding-left: 10px;
    }
  }
}
.card-bg {
  background-color: #00a4b3 !important;
}
.btn-purple {
  background-color: #1f3c87 !important;
  color: #fff;
  min-height: 38px;
}
.theme--light.card-bg {
  border: unset !important;
}
.service-list,
.v-list--dense .v-list-item {
  min-height: 30px !important;
}

.service-list,
.v-list-item__action {
  margin: 0px !important;
  margin-right: 10px !important;
}
@media screen and (min-width: 767px) {
  .slider-mobile {
    display: none;
  }
  .mb-bg {
    margin-top: 80px;
  }
}
</style>
