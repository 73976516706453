<template>
  <div class="slider">
    <v-carousel
      class="carousel-promo-item"
      height="auto"
      width="auto"
      :show-arrows="false"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <slot name="item" v-bind="item">
          <picture>
            <!-- mobile -->
            <img :src="item.src_mobile" class="banner" alt="Banner" />
          </picture>
        </slot>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  .banner {
    width: 100%;
    height: auto;
  }
}
</style>
